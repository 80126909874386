/* quản lý danh mục style */

.category-table .table th,
.category-table .table td {
  border-top: 1px solid #eaeaea;
}

.category-table .table {
  border: 1px solid #eaeaea;
  border-radius: 3px;
}

.category-detail-table .table {
  min-width: 520px;
}

.wrap-table__cate {
  margin-top: 30px;
}

.icon-small {
  max-width: 60px;
  max-height: 100px;
  object-fit: cover;
}

.line {
  position: absolute;
  font-size: 16px;
  color: #383838;
  left: 50%;
  top: 10px;
  transform: translateX(-50%);
}

.header-cell {
  border-left: 1px solid rgba(224, 224, 224, 1);
}

@media only screen and (max-width: 767px) {
  .form__save button {
    margin-bottom: 15px;
    margin-left: 0px;
  }
}
