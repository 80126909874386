/* ========= info-user-style ========= */

.user-page {
  padding-top: 24px;
}

.user__form {
  padding-bottom: 50px;
}

.form__top {
  gap: 20px;
  align-items: stretch;
}

.main__form {
  flex: 1;
}

.user__avatar {
  max-width: 366px;
  width: 32%;
  min-width: 260px;
  border: 1px solid #c0c1c4;
  border-radius: 3px;
  padding: 50px 0px 80px;
}

.user__avatar > h5 {
  font-size: 14px;
  font-weight: 500;
  color: var(--text-color);
  text-align: center;
  margin-bottom: 20px;
}

.user__img {
  position: relative;
}

.user__img > img {
  max-width: 150px;
  margin: 0 auto;
  border-radius: 2px;
}

.user__img button {
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translate(-50%, -50%);
  border: 1px solid #eaeaea;
  background-color: #fff;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.user__img button > img {
  width: 12.8px;
}

.form-group label {
  font-size: 14px;
  color: var(--text-color);
  font-weight: 500;
}

.form-group {
  margin-bottom: 18px;
}

.main__form .form-group:last-of-type {
  margin-bottom: 0px;
}
