.card {
  margin-bottom: 1.875rem;
  background-color: #fff;
  transition: all 0.5s ease-in-out;
  position: relative;
  border: 0px solid transparent;
  border-radius: 0.25rem;
  box-shadow: 0px 0px 13px 0px rgb(82 63 105 / 5%);
}

.stat-widget-two {
  text-align: center;
}
.stat-widget-two .stat-text {
  font-size: 16px;
  margin-bottom: 5px;
  color: #868e96;
}
.stat-widget-two .stat-digit {
  font-size: 1.75rem;
  font-weight: 500;
  color: #373757;
}

.card-header {
  border-color: #eaeaea;
  position: relative;
  background: transparent;
  padding: 1.25rem 1.25rem 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 0px;
}

.card-header .card-title {
  margin-bottom: 0px;
}

.card-title {
  font-size: 16px;
  font-weight: 500;
  color: #454545;
  text-transform: capitalize;
  letter-spacing: 0.05em;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

@media (max-width: 992px) {
  .col {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-2 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
