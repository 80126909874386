.pay-content-reflect {
  padding: 25px;
  width: calc(100% - 200px);
  margin: 0;
}

.waiting-process-pagination {
  width: 100%;
  overflow: hidden;
}

.reflection-table-action {
  white-space: nowrap;
}

.cursor-pointer {
  cursor: pointer;
}

.flex {
  display: flex;
}

.align-items {
  align-items: center;
}

.p-0 {
  padding: 0px;
}

.pl-50px {
  padding: 0px !important;
  padding-left: 50px !important;
}

.map-drawer .MuiBackdrop-root {
  background-color: unset;
}

.map-view-detail {
  color: #007bff;
  cursor: pointer;
  margin-top: 15px;
  text-align: right;
}
