@import url(https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@300;400;500;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Mulish:ital@0;1&display=swap);
/* home page style */
.home__files {
  width: 100%;
  padding-top: 37px;
  padding-bottom: 45px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.file__item {
  width: calc(100% / 2 - 15px);
  border-radius: 3px;
  border: 1px solid #eaeaea;
  background-color: #fff;
  padding: 30px 2.5% 30px 4%;
  margin-bottom: 20px;

  display: flex;
  align-items: flex-start;
  position: relative;
  grid-gap: 40px;
  gap: 40px;
}

.file__img {
  max-width: 150px;
  position: relative;
}

.file__desc {
  flex: 1 1;
}

.file__select {
  position: absolute;
  width: 76px;
  height: 30px !important;
  top: 30px;
  right: 30px;
  font-size: 14px;
  color: var(--text-color);

  border-radius: 3px;
  line-height: 30px !important;
  padding-top: 0px;
  padding-bottom: 0px;
  border-color: #c0c1c4 !important;
  cursor: pointer;
}

.file__select.custom-select {
  background: #fff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAMCAYAAABm+U3GAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACKSURBVHgBtdDLCcAgDAZg4wSO0BG6Qpfy7E1wqY7QjtARvHqyKi2IaHzU/mAgKl8ghPwU8EVKuVBKD2stI3NyUV+NMZpMDACwAAshtGs2dz4P8Eaw4kul1OrWsY+u5EU55yekj6N4jIY+96kXT9Ei3IPnUBRuwUtoFcZwDG2Cc3gNbYZj/IFR1OcG1CNrUS1PTnAAAAAASUVORK5CYII=) no-repeat right 10.5px center;
  background-size: 10.5px;
}

.file__desc > h4 {
  font-size: 18px;
  font-weight: 500;
  color: var(--title-color);
  width: calc(100% - 100px);
}

.file__desc ul {
  margin-top: 25px;
}

.file__desc ul li {
  font-size: 16px;
  padding: 2px 0px 5px 15px;
  font-weight: 500;
  color: var(--text-color-second);
  position: relative;
}

.file__desc p {
  font-size: 16px;
  margin-top: 5px;
  font-weight: 500;
  color: var(--text-color-second);
  position: relative;
}

.file__desc ul li::after {
  position: absolute;
  content: "";
  left: 0px;
  top: 40%;
  width: 6px;
  height: 6px;
  background-color: tomato;
  border-radius: 50%;
}

.file__desc ul li:nth-child(1)::after {
  background-color: #2dcc70;
}

.file__desc ul li:nth-child(2)::after {
  background-color: #ff646c;
}

.info__list .table thead th:nth-child(2),
.info__list .table td:nth-child(2) {
  width: 18%;
}

.info__list table {
  border: 1px solid #eaeaea;
  min-width: 1200px;
  overflow-x: auto;
}

.info__list .table thead th {
  border-color: #cccccc;
}

.percent {
  position: absolute;
  font-size: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2dcc70;
}

@media only screen and (max-width: 1200px) {
  .bar-chart {
    min-width: 600px;
  }
  .home__files {
    overflow-x: auto;
    padding: 20px 0px;
  }
  .file__item {
    min-width: 470px;
    overflow-x: auto;
    width: 100%;
    margin-bottom: 20px;
    padding: calc(20px);
  }
  .file__item:last-child {
    margin-bottom: 0px;
  }
  .file__img {
    /* max-width: 90px; */
    margin-right: 20px;
  }

  .home__files::-webkit-scrollbar {
    background-color: #ebebeb;
    height: 8px;
  }

  .home__files::-webkit-scrollbar-thumb {
    background-color: #c4c4c4;
    border-radius: 10px;
  }
}

.card {
  margin-bottom: 1.875rem;
  background-color: #fff;
  transition: all 0.5s ease-in-out;
  position: relative;
  border: 0px solid transparent;
  border-radius: 0.25rem;
  box-shadow: 0px 0px 13px 0px rgb(82 63 105 / 5%);
}

.stat-widget-two {
  text-align: center;
}
.stat-widget-two .stat-text {
  font-size: 16px;
  margin-bottom: 5px;
  color: #868e96;
}
.stat-widget-two .stat-digit {
  font-size: 1.75rem;
  font-weight: 500;
  color: #373757;
}

.card-header {
  border-color: #eaeaea;
  position: relative;
  background: transparent;
  padding: 1.25rem 1.25rem 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 0px;
}

.card-header .card-title {
  margin-bottom: 0px;
}

.card-title {
  font-size: 16px;
  font-weight: 500;
  color: #454545;
  text-transform: capitalize;
  letter-spacing: 0.05em;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

@media (max-width: 992px) {
  .col {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-2 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

/* quản lý danh mục style */

.category-table .table th,
.category-table .table td {
  border-top: 1px solid #eaeaea;
}

.category-table .table {
  border: 1px solid #eaeaea;
  border-radius: 3px;
}

.category-detail-table .table {
  min-width: 520px;
}

.wrap-table__cate {
  margin-top: 30px;
}

.icon-small {
  max-width: 60px;
  max-height: 100px;
  object-fit: cover;
}

.line {
  position: absolute;
  font-size: 16px;
  color: #383838;
  left: 50%;
  top: 10px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.header-cell {
  border-left: 1px solid rgba(224, 224, 224, 1);
}

@media only screen and (max-width: 767px) {
  .form__save button {
    margin-bottom: 15px;
    margin-left: 0px;
  }
}

/* ========= Change-password-style ========= */

.change-pass__page {
  padding-top: 26px;
}

.change-pass__form {
  padding-bottom: 50px;
  padding-top: 50px;
}

.change-pass__form form {
  max-width: 615px;
  margin: 0 auto;
}

.change-pass__form .form-group label {
  font-size: 14px;
  font-weight: 500;
  color: var(--text-color);
  margin-bottom: 0px;
}

.form-group label span {
  color: var(--btn-danger-ct) !important;
}

.form-group {
  margin-bottom: 18px;
}

/* ========= info-user-style ========= */

.user-page {
  padding-top: 24px;
}

.user__form {
  padding-bottom: 50px;
}

.form__top {
  grid-gap: 20px;
  gap: 20px;
  align-items: stretch;
}

.main__form {
  flex: 1 1;
}

.user__avatar {
  max-width: 366px;
  width: 32%;
  min-width: 260px;
  border: 1px solid #c0c1c4;
  border-radius: 3px;
  padding: 50px 0px 80px;
}

.user__avatar > h5 {
  font-size: 14px;
  font-weight: 500;
  color: var(--text-color);
  text-align: center;
  margin-bottom: 20px;
}

.user__img {
  position: relative;
}

.user__img > img {
  max-width: 150px;
  margin: 0 auto;
  border-radius: 2px;
}

.user__img button {
  position: absolute;
  left: 50%;
  top: 100%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border: 1px solid #eaeaea;
  background-color: #fff;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.user__img button > img {
  width: 12.8px;
}

.form-group label {
  font-size: 14px;
  color: var(--text-color);
  font-weight: 500;
}

.form-group {
  margin-bottom: 18px;
}

.main__form .form-group:last-of-type {
  margin-bottom: 0px;
}

/* common style */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  line-height: 1.4;
  font-family: "Roboto", sans-serif;
  background-color: #e5e5e5;
}

@media only screen and (max-width: 767px) {
  body {
    min-width: 320px;
    overflow-x: hidden;
  }
}

a {
  text-decoration: none;
  color: unset;
  transition: 0.2s;
}

a:hover {
  text-decoration: none;
}

ul,
li {
  list-style-type: none;
  margin: 0px;
}

img {
  display: block;
  width: 100%;
  transition: 0.2s;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0px;
}

.h100 {
  height: 100%;
}

.container {
  /* custum */
  max-width: 1170px;
  margin: 0 auto;
}

:root {
  --title-color: #0055ab;
  --btn-primary-ct: #003f8e;
  --btn-danger-ct: #f24a4a;
  --btn--success-ct: #1ba466;
  --text-color: #383838;
  --text-color-second: #5f5f5f;
}

.only-pc {
  display: block;
}

.only-sp {
  display: none;
}

@media only screen and (max-width: 767px) {
  .only-pc {
    display: none;
  }

  .only-sp {
    display: block;
  }
}

/* ==========header========== */
header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 500;
  width: 100%;
  height: auto;
  background-color: #003f8e;
}

header + div {
  height: 60px;
}

.header__inner {
  background-image: url(/static/media/header-bg.b4767f5c.png);
  height: 60px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.header__logo {
  padding-left: 15px;
}

.header__logo img {
  max-width: 50px;
}

.header__logo .site-title {
  margin-left: 10px;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}

.header__menu-button {
  color: #fff;
}

@media screen and (min-width: 992px) {
  .header__menu-button {
    display: none !important;
  }
}

.header__admin {
  padding-right: 28px;
  cursor: pointer;
  position: relative;
}

.header__admin img.avatar {
  width: 26px;
}

.admin__text p {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  margin: 0px 6px 0px 8px;
}

.admin__text img {
  width: 8px;
}

.admin-popup {
  position: absolute;
  border: 1px solid #e0e0e0;
  background-color: #fff;
  z-index: 10;
  top: 43px;
  right: 0;
  padding: 7px 0px;
  display: none;
}

.admin-popup.is__open {
  display: block;
}

.admin-popup li {
  padding: 9px 15px;
}

.admin-popup li a,
.admin-popup li .logout {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #383838;
  color: var(--text-color);
  white-space: nowrap;
}

.admin-popup li a:hover,
.admin-popup li .logout:hover {
  color: #0055ab;
  color: var(--title-color);
}

.admin-popup li a img {
  width: 14px;
  margin-right: 10px;
}

.admin-popup li .logout img {
  width: 14px;
  margin-right: 10px;
}
/* ==========header========== */

.wrap-page {
  width: 100%;
  position: relative;
  height: auto;
  flex-wrap: nowrap;
  align-items: flex-start;
}

.wrap-page__content {
  flex: 1 1;
  overflow-x: hidden;
}

/* ======aside====== */
aside {
  background-color: #fff;
  height: 100%;
  box-shadow: 2px 0px 6px 0px #00000026;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.aside__wrapper--mobile {
  width: 300px;
  max-width: 85vw;
}

.aside__wrapper--desktop {
  width: 0;
  transition: all 150ms ease-in-out;
  overflow: hidden;
}
@media screen and (min-width: 992px) {
  .aside__wrapper--desktop {
    background-color: #fff;
    min-width: 200px;
    width: 15%;
    max-width: 300px;
    position: -webkit-sticky;
    position: sticky;
    top: 60px;
    height: calc(100vh - 60px);
    overflow-y: hidden;
  }
}

.aside__menu {
  position: relative;
  width: 100%;
  max-height: calc(100% - 117px);
  overflow-y: auto;
  flex: 1 1;
}

.aside__menu > li {
  position: relative;
}

.aside__menu > li .aside__menu-sub {
  background-color: #ecf7fe;
  transition: height 0.3s ease;
}

.aside__menu > li.is__open .aside__menu-sub {
  display: block;
}

.aside__menu-sub li:first-child {
  padding-top: 5px;
}
.aside__menu-sub li:last-child {
  padding-bottom: 5px;
}

.aside__menu-sub li a,
.aside__menu-sub li .btn-sidebar {
  display: block;
  font-weight: 400;
  padding: 8.5px 15px;
  font-size: 14px;
  color: #5f5f5f;
  color: var(--text-color-second);
  transition: 0.2s;
}

.arrow-icon {
  margin-left: auto;
}

.aside__menu li a,
.aside__menu li .btn-sidebar {
  position: relative;
}

.aside__menu li a > span,
.aside__menu li .btn-sidebar > span {
  position: absolute;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border-radius: 50%;
  background-color: #f24a4a;
  font-size: 11px;
  font-weight: 500;
  right: 12px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  color: #fff;
}

.aside__menu-sub li a.is__active,
.aside__menu-sub li .btn-sidebar.is__active {
  font-weight: 500;
}

.aside__menu > li > a,
.aside__menu > li > .btn-sidebar {
  display: flex;
  align-items: center;
  font-weight: 400;
  padding: 8.5px 15px;
  font-size: 14px;
  color: #5f5f5f;
  color: var(--text-color-second);
  cursor: pointer;
}

.aside__menu > li > a svg,
.aside__menu > li > .btn-sidebar svg {
  fill: #5f5f5f;
}

.aside__menu > li > a.is__active,
.aside__menu > li > .btn-sidebar.is__active {
  background-color: #359ae1;
  font-weight: 500;
  color: #fff;
}

.aside__menu > li > a.is__active svg,
.aside__menu > li > .btn-sidebar.is__active svg {
  fill: #fff;
}

.aside__menu-icon {
  width: 28px;
  display: flex;
  align-items: center;
}

.aside__menu > li > a > svg,
.aside__menu > li > .btn-sidebar > svg {
  max-width: 20px;
  width: auto;
  transition: 0.2s;
  fill: #5f5f5f;
}

.copyright {
  padding: 0 15px 20px;
}

.copyright p {
  font-size: 13px;
  color: #383838;
  color: var(--text-color);
  font-weight: 300;
  margin-top: 12px;
}

/* ======aside====== */

/* ====== Login-style ====== */
.login-page {
  width: 100vw;
  height: calc(100vh - 60px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-box {
  border: 1px solid #eaeaea;
  border-radius: 3px;
  background-color: #fff;
  padding: 55px 48px 60px;
  max-width: 465px;
  transition: 0.2s;
}

.login-box > h3 {
  color: #0055ab;
  color: var(--title-color);
  font-size: 24px;
  font-weight: 500;
  text-align: center;
}

.login-box form {
  margin-top: 45px;
}

.login-box form .form-group {
  margin-bottom: 22px;
}

.login-box form .form-group label {
  font-size: 16px;
  color: #383838;
  color: var(--text-color);
  font-weight: 400;
  margin-bottom: 6px;
}

.login-box form .form-group input {
  border-radius: 2px;
  padding: 8px 12px;
}

.f-confirm .form-group {
  width: 48%;
}

.confirm__code {
  margin-left: 20px;
  flex: 1 1;
}

.confirm__code button {
  background-color: #383838;
  background-color: var(--text-color);
  border-radius: unset;
  padding: 0px;
  height: 42px;
  line-height: 42px;
  min-width: 126px;
}

.confirm__code button span {
  font-size: 24px;
  color: #fff;
  display: inline-block;
  font-weight: 300;
  margin: 0 3px;
}

.confirm__code button span:nth-child(1) {
  -webkit-transform: rotate(-15deg);
          transform: rotate(-15deg);
}

.confirm__code button span:nth-child(2) {
  -webkit-transform: rotate3d(1, 1, 1, 20deg);
          transform: rotate3d(1, 1, 1, 20deg);
}

.confirm__code button span:nth-child(3) {
  -webkit-transform: rotate3d(-1, -1, -1, -15deg);
          transform: rotate3d(-1, -1, -1, -15deg);
}

.confirm__code img {
  max-width: 20px;
  margin-left: 17px;
  cursor: pointer;
}

.login-box form button[type="button"] {
  width: 100%;
  margin-top: 40px;
  border-radius: 3px;
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  background-color: #003f8e;
  background-color: var(--btn-primary-ct);
  padding: 10px;
  border: 1px solid #003f8e;
  border: 1px solid var(--btn-primary-ct);
  transition: 0.2s;
}

.login-box form button[type="submit"]:hover {
  background-color: transparent;
  color: #0055ab;
  color: var(--title-color);
}

@media only screen and (max-width: 600px) {
  .login-box > h3 {
    font-size: 22px;
  }
  .login-page {
    padding: 15px;
    width: 100%;
  }
  .login-box {
    max-width: 100%;
    padding: 30px 15px 40px;
  }
  .login-box form {
    margin-top: 25px;
  }
  .login-box form .form-group input {
    padding: 6px 10px;
  }
  .login-box form .form-group label {
    font-size: 14px;
  }
  .confirm__code button {
    height: 38px;
    line-height: 38px;
    padding: 0px 20px;
    min-width: unset;
  }
  .confirm__code button span {
    font-size: 18px;
  }
  .login-box form button[type="submit"] {
    margin-top: 25px;
    padding: 6px 10px;
    font-size: 15px;
  }
}
/* ====== Login-style ====== */

.page-content {
  flex: 1 1;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  overflow-x: auto;
  padding-bottom: 30px;
}

.page__title {
  padding-bottom: 8px;
  border-bottom: 1px solid #cccccc;
}

.page__title span {
  font-size: 16px;
  font-weight: 700;
  color: #0055ab;
  color: var(--title-color);
  position: relative;
  padding-bottom: 8px;
}

.page__title span::after {
  position: absolute;
  content: "";
  width: 100%;
  top: 104%;
  left: 0;
  height: 2px;
  background-color: #0055ab;
  background-color: var(--title-color);
}

.wrap__content-page {
  border-radius: 3px;
  margin-top: 22px;
  background-color: #fff;
  width: 100%;
  padding: 30px;
  border: 1px solid #eaeaea;
}

.form__save {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-ct {
  min-width: 138px;
  margin: 0px 15px;
  border-radius: 3px;
  font-size: 16px;
  font-weight: 500;
  border: none;
  transition: 0.2s;
  min-height: 40px;
  color: #fff;
  padding: 6px 22px;
}

.btn-custom-ct {
  border-radius: 3px;
  font-size: 16px;
  font-weight: 500;
  border: none;
  transition: 0.2s;
  color: #fff;
  min-height: 40px;
}

.btn-ct:hover {
  opacity: 0.7;
}

.btn-default-ct {
  background-color: #dddddd;
  color: #383838;
  color: var(--text-color);
}

.btn-primary-ct {
  background-color: #003f8e;
  background-color: var(--btn-primary-ct);
}

.btn-danger-ct {
  background-color: #f24a4a;
  background-color: var(--btn-danger-ct);
}

.btn-success-ct {
  background-color: #1ba466;
  background-color: var(--btn--success-ct);
}

.btn-back-ct {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-back-ct > img {
  width: 18px;
  margin-right: 3px;
}

.btn-back-bg {
  background-color: #359ae1;
  color: #fff;
}

.form-row {
  margin-right: -10px;
  margin-left: -10px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-left: 10px;
  padding-right: 10px;
}

.form-control {
  padding: 9px 12px;
  font-size: 14px;
  color: #4e4e4e;
  font-weight: 400;
  max-height: 40px;
}

.custom-select {
  background: #fff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAMCAYAAABm+U3GAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACKSURBVHgBtdDLCcAgDAZg4wSO0BG6Qpfy7E1wqY7QjtARvHqyKi2IaHzU/mAgKl8ghPwU8EVKuVBKD2stI3NyUV+NMZpMDACwAAshtGs2dz4P8Eaw4kul1OrWsY+u5EU55yekj6N4jIY+96kXT9Ei3IPnUBRuwUtoFcZwDG2Cc3gNbYZj/IFR1OcG1CNrUS1PTnAAAAAASUVORK5CYII=) no-repeat right 11.5px center;
  background-size: 11.5px;
  font-size: 14px;
  color: #4e4e4e;
  font-weight: 400;
  height: 40px;
}

.form-group > label {
  font-size: 14px;
  font-weight: 500;
  color: #383838;
  color: var(--text-color);
}

.form-group > label span {
  color: #f24a4a;
  color: var(--btn-danger-ct);
}

/* table-common style */
.wrap-table {
  overflow-x: auto;
}

.wrap-table::-webkit-scrollbar {
  background-color: #ebebeb;
  height: 8px;
}

.wrap-table::-webkit-scrollbar-thumb {
  background-color: #c4c4c4;
  border-radius: 10px;
}

.wrap-table > h3 {
  font-size: 18px;
  color: #0055ab;
  color: var(--title-color);
  font-weight: 500;
  margin-bottom: 24px;
}

.wrap-table table {
  background-color: #fff;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #eef0f2;
}

.wrap-table .table thead th {
  font-weight: 500;
  position: relative;
}

.wrap-table .table thead th img {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 20%;
  width: 9px;
}

.wrap-table .table th,
.wrap-table .table td {
  border-top: none;
  font-size: 14px;
  font-weight: 400;
  color: #383838;
  color: var(--text-color);
}

.table thead th,
.table td {
  vertical-align: middle;
}

.table td .table-action img {
  max-width: 18px;
  margin: 0px 9px;
}
/* table-common style */

/* =====pagination===== */
.pagination {
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.pagination > p {
  font-size: 14px;
  color: #383838;
  color: var(--text-color);
  font-weight: 400;
}

.pagination button,
.pagination select {
  margin-left: 6px;
  border: 1px solid #cccccc;
  background-color: #fff;
  border-radius: 1px;
  height: 30px;
  cursor: pointer;
}

.pagination .disabled {
  pointer-events: none;
  opacity: 0.7;
}

.pagination button {
  width: 30px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagination button img {
  height: 10px;
  width: auto;
}

.pagination select {
  max-width: 60px;
}
/* =====end-pagination===== */

/* thông tin công trinh, dự án */
.info__project form .form-group {
  margin-bottom: 18px;
}

.info__project {
  margin-bottom: 20px;
}

.info__project > h4 {
  font-size: 16px;
  font-weight: 500;
  color: #0055ab;
  color: var(--title-color);
  margin-bottom: 22px;
}

.file__attach.wrap-table .table th,
.file__attach.wrap-table .table td {
  font-size: 16px;
  padding: 8px 12px;
  vertical-align: middle;
}

.table-input input {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}

.table__avatar {
  max-width: 58px;
  max-height: 58px;
  border-radius: 2px;
  overflow: hidden;
}

.table__avatar img {
  width: 58px;
  height: 58px;
  border-radius: 50%;
  object-fit: cover;
}

.wrap-table__user {
  margin-top: 30px;
}

.table__user.table td,
.table__user.table th {
  padding: 13px 20px;
  vertical-align: middle;
}

.message-list.table td,
.message-list.table th {
  padding: 15px 20px;
  vertical-align: middle;
}

.message-list.table tbody tr {
  background-color: #fff;
}

.add__row .btn-ct {
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  min-height: unset;
  font-size: 16px;
  font-weight: 400;
  width: 120px;
  min-width: unset;
}

.add__row .btn-ct > img {
  width: 12px;
  margin-right: 4px;
}

.table-input {
  width: 100%;
  margin: 0 auto;
}

.table-input .btn-ct {
  max-height: 34px;
  min-height: unset;
  font-weight: 400;
  width: 120px;
  min-width: unset;
  margin: 0px;
}

.btn-ct > img {
  max-width: 24px;
  margin-right: 3px;
}

.btn-ct.btn-add > img {
  max-width: 18px;
  margin-left: 5px;
  margin-right: 0px;
  margin-top: 2px;
}

.btn-action {
  font-size: 14px;
  font-weight: 400;
  color: #ff9238 !important;
  padding: 4px 15px;
  border: 1px solid #ff9238;
  border-radius: 3px;
  background-color: #fff;
  transition: 0.2s;
  min-width: 85px;
}

.btn-action:hover {
  opacity: 0.7;
}

.btn-action.is__active {
  color: #1ba466 !important;
  color: var(--btn--success-ct) !important;
  border-color: #1ba466;
  border-color: var(--btn--success-ct);
}

/* thông tin công trinh, dự án */

.select-ct-radius {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.input-ct-border {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.form__save-ct {
  flex: 1 1;
}

.action__user > img {
  max-width: 18px;
  margin-right: 8px;
}

.action__user {
  cursor: pointer;
}

.table td .action__user button {
  margin-top: 3px;
  text-decoration: none;
  color: #359ae1;
  cursor: pointer;
  outline: none;
  background-color: transparent;
}

.table td .action__user a {
  margin-top: 3px;
  text-decoration: none;
  color: #359ae1;
}

.table td .action__user a.deleted {
  color: #f24a4a;
  color: var(--btn-danger-ct);
}

/* modal */
.modal-dialog {
  max-width: 600px;
}

.modal-header {
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #003f8e;
  background-color: var(--btn-primary-ct);
}

.modal-header > h5 {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}

.modal-header button {
  outline: none;
}

.modal-header button.close {
  opacity: 1;
}

.modal-header button > img {
  width: 24px;
}

.modal-select-avatar > h4 {
  font-size: 14px;
  font-weight: 500;
  color: #383838;
  color: var(--text-color);
  margin-bottom: 10px;
}

.modal-select-avatar .inner {
  border: 1px solid #c0c1c4;
  border-radius: 3px;
  padding: 24px;
  text-align: center;
}

.modal-select-avatar .inner > h5 {
  font-size: 14px;
  font-weight: 400;
  color: #383838;
  color: var(--text-color);
}

.modal-select-avatar .inner > p {
  color: #5f5f5f;
  font-size: 12px;
  font-weight: 400;
  margin-top: 2px;
  margin-bottom: 5px;
}

.modal-select-avatar .inner > span {
  display: block;
  font-size: 14px;
  font-weight: 400;
  color: #888888;
  margin-top: 15px;
}

.modal-select-avatar .img {
  width: 100px;
  position: relative;
  margin: 0 auto;
}

.modal-select-avatar .img button {
  position: absolute;
  border: none;
  outline: none;
  background-color: #fff;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  padding: 5px;
  top: 100%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  cursor: pointer;
}

.MuiInputAdornment-root {
  position: absolute;
  right: 25px;
}
.MuiInputAdornment-root:focus-visible,
.MuiInputAdornment-root:focus {
  outline: none;
}

.MuiTouchRipple-root:focus-visible {
  outline: none;
}

button:focus {
  outline: none !important;
}

/* modal */

.ck-editor__editable_inline {
  min-height: 200px;
}

.file__block {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20p;
  margin-bottom: 20px;
}

.file__block img {
  width: 100%;
}

.file__block .btn-delete {
  width: 24px;
  cursor: pointer;
}

.text_ellipsis_2 {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
}

.text_ellipsis_1 {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
}

@media only screen and (max-width: 1360px) {
  .form__save-ct {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 991px) {
  .wrap__content-page {
    padding: 15px;
  }
  .form__save {
    flex-wrap: wrap;
  }
  .form__save button {
    margin-bottom: 20px;
  }
}
.icon {
  max-width: 18px;
  margin: 0px 9px;
  cursor: pointer;
}
.loading {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.loading .spinner {
  height: 20px;
  width: 20px;
}
.border-error-tr {
  border: 1.5px solid #d32f2f;
}
.form-section {
  background-color: #e8eef0;
  padding: 20px;
  border-radius: 7px;
  margin-bottom: 20px;
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
}
.action-form-section {
  position: absolute !important;
  right: 28px;
  cursor: pointer;
  z-index: 2;
}

.async-select {
  width: 100%;
}
.single-value {
  font-size: 14px;
}
.invalid > div {
  border: 1px solid #d32f2fed;
}

.MuiOutlinedInput-root {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.MuiFormControl-root.is-invalid {
  border: 1px solid #dc3545;
}

.m-w-335 {
  min-width: 335px;
}

/* quản lý hồ sơ style */
.form-group {
  margin-bottom: 20px;
}

.rowqlds-ct {
  position: relative;
}

.rowqlds-ct .line {
  position: absolute;
  font-size: 16px;
  color: var(--text-color);
  left: 50%;
  top: 10px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.form__save {
  margin-top: 10px;
  padding-top: 30px;
  border-top: 1px solid #c0c1c4;
}

.qlds__list {
  margin-top: 35px;
  padding-top: 15px;
  overflow-x: auto;
}

.qlds__list table {
  overflow-x: auto;
  min-width: 1300px;
}

.qlds__list::-webkit-scrollbar {
  background-color: #ebebeb;
  height: 8px;
}

.qlds__list::-webkit-scrollbar-thumb {
  background-color: #c4c4c4;
  border-radius: 10px;
}

.qlds__list table td select {
  min-width: 120px;
  height: 34px;
}

.qlds__list table td a {
  color: #359ae1;
  text-decoration: underline;
}

.table thead th,
.table td {
  vertical-align: top;
}

.table td,
.table th {
  padding: 12px 6px;
}

.wrap-table .table thead th {
  white-space: nowrap;
}

.danhsach-ctrinh-table .table td,
.danhsach-ctrinh-table .table th {
  padding: 12px;
}

.danhsach-ctrinh-table {
  overflow-x: auto;
  margin-top: 35px;
}

.danhsach-ctrinh-table table {
  min-width: 650px;
}

.danhsach-ctrinh-table table td select {
  min-width: 120px;
}

.table td a {
  color: #359ae1;
  text-decoration: underline;
}

.table__inner tbody tr:nth-of-type(odd) {
  background-color: transparent;
}

.report-page {
  padding-top: 40px;
  padding-bottom: 40px;
}

.report__row {
  margin-bottom: 15px;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #eaeaea;
  padding: 19px 28px;
  transition: 0.2s;
  cursor: pointer;
}

.report__title {
  padding-right: 20px;
}

.report__row:hover {
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
}

.report__row:last-child {
  margin-bottom: 0px;
}

.report__title > img {
  width: 44px;
  margin-right: 20px;
}

.report__title > h4 {
  font-size: 15px;
  font-weight: 500;
  color: var(--text-color);
  text-transform: uppercase;
}

.report__img-right > img {
  width: 24px;
}

/* report detail page */
.form__save {
  margin-top: 10px;
}

.report-page__inner {
  border-radius: 3px;
  border: 1px solid #eaeaea;
  background-color: #fff;
  padding-bottom: 50px;
}

.report-page__inner > h5 {
  padding: 20px 20px 15px 20px;
  border-bottom: 2px solid #eaeaea;
  font-size: 14px;
  font-weight: 500;
  color: var(--text-color);
}

.form__report-detail {
  padding: 15px 20px 0px;
}
/* end report detail page */

@media only screen and (max-width: 991px) {
  .report-page {
    overflow-x: auto;
  }
  .report-page__inner {
    min-width: 750px;
    overflow-x: auto;
  }
  .report__title > h4 {
    font-size: 14px;
  }
  .report__title > img {
    width: 30px;
    margin-right: 10px;
  }
  .report__row {
    padding: 10px 15px;
  }
}

/* page tdtt style */

.tdtt__item {
  padding: 13.5px 20px;
  border-bottom: 1px solid #eaeaea;
}

.tdtt__item:last-child {
  border-bottom: none;
}

.tdtt__item-title {
  flex: 1 1;
  padding-right: 20px;
}

.tdtt__item-title a {
  font-size: 14px;
  font-weight: 500;
  color: var(--text-color);
}

.tdtt__item-r {
  font-size: 14px;
  font-weight: 500;
  color: var(--text-color);
}

.tdtt__item-r > p {
  margin-left: 20px;
}

.tdtt__item-r > p:first-child {
  margin-left: 0px;
}

.tdtt-page__content {
  padding: 22px 20px;
}

.tdtt__content > h3.title {
  font-size: 18px;
  font-weight: 400;
  color: var(--text-color);
  margin-bottom: 18px;
}

.tdtt__location {
  padding-bottom: 14px;
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 15px;
}

.tdtt__location-list {
  border-top: 1px solid #eaeaea;
  border-bottom: none;
  padding-top: 20px;
  margin-bottom: 0;
}

.tdtt__location > h4 {
  font-size: 14px;
  font-weight: 700;
  color: var(--text-color);
  padding-right: 20px;
}

.tdtt__location > span {
  font-size: 14px;
  font-weight: 400;
  color: #888888;
}

.tdtt__content > p,
.tdtt__content > .tdtt__content-inner {
  font-size: 14px;
  line-height: 20px;
  color: var(--text-color);
  margin-bottom: 15px;
}

.tdtt__content > figure {
  margin-bottom: 20px;
}

.tdtt__content > figure img {
  max-width: 180px;
}

.tdtt-form {
  background-color: #fff;
  margin-top: 30px;
  border-radius: 3px;
  border: 1px solid #eaeaea;
}

.tdtt-form form textarea {
  width: 100%;
  height: 220px;
  border: none;
  outline: none;
  padding: 20px;
  font-size: 16px;
}

.tdtt-form-send {
  border-top: 1px solid #eaeaea;
  padding: 20px;
}

.tdtt-form-send > img {
  max-width: 20px;
}

.tdtt-form-send .upload-file {
  width: 95%;
  margin-right: 10px;
}

.btn-tdtt-form {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  padding: 7px 30px;
}

.form-control {
  max-height: unset;
}

.form-check > label {
  font-size: 15px;
  font-weight: 400;
  color: var(--text-color);
}

@media only screen and (max-width: 767px) {
  .wrap__content-page {
    overflow-x: auto;
  }
  .tdtt__list,
  .tdtt__content {
    min-width: 560px;
    overflow-x: auto;
  }
  .tdtt-form-send .upload-file {
    width: 100%;
  }
}

.pay-content-reflect {
  padding: 25px;
  width: calc(100% - 200px);
  margin: 0;
}

.waiting-process-pagination {
  width: 100%;
  overflow: hidden;
}

.reflection-table-action {
  white-space: nowrap;
}

.cursor-pointer {
  cursor: pointer;
}

.flex {
  display: flex;
}

.align-items {
  align-items: center;
}

.p-0 {
  padding: 0px;
}

.pl-50px {
  padding: 0px !important;
  padding-left: 50px !important;
}

.map-drawer .MuiBackdrop-root {
  background-color: unset;
}

.map-view-detail {
  color: #007bff;
  cursor: pointer;
  margin-top: 15px;
  text-align: right;
}

