/* home page style */
.home__files {
  width: 100%;
  padding-top: 37px;
  padding-bottom: 45px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.file__item {
  width: calc(100% / 2 - 15px);
  border-radius: 3px;
  border: 1px solid #eaeaea;
  background-color: #fff;
  padding: 30px 2.5% 30px 4%;
  margin-bottom: 20px;

  display: flex;
  align-items: flex-start;
  position: relative;
  gap: 40px;
}

.file__img {
  max-width: 150px;
  position: relative;
}

.file__desc {
  flex: 1;
}

.file__select {
  position: absolute;
  width: 76px;
  height: 30px !important;
  top: 30px;
  right: 30px;
  font-size: 14px;
  color: var(--text-color);

  border-radius: 3px;
  line-height: 30px !important;
  padding-top: 0px;
  padding-bottom: 0px;
  border-color: #c0c1c4 !important;
  cursor: pointer;
}

.file__select.custom-select {
  background: #fff url(../image/down02.png) no-repeat right 10.5px center;
  background-size: 10.5px;
}

.file__desc > h4 {
  font-size: 18px;
  font-weight: 500;
  color: var(--title-color);
  width: calc(100% - 100px);
}

.file__desc ul {
  margin-top: 25px;
}

.file__desc ul li {
  font-size: 16px;
  padding: 2px 0px 5px 15px;
  font-weight: 500;
  color: var(--text-color-second);
  position: relative;
}

.file__desc p {
  font-size: 16px;
  margin-top: 5px;
  font-weight: 500;
  color: var(--text-color-second);
  position: relative;
}

.file__desc ul li::after {
  position: absolute;
  content: "";
  left: 0px;
  top: 40%;
  width: 6px;
  height: 6px;
  background-color: tomato;
  border-radius: 50%;
}

.file__desc ul li:nth-child(1)::after {
  background-color: #2dcc70;
}

.file__desc ul li:nth-child(2)::after {
  background-color: #ff646c;
}

.info__list .table thead th:nth-child(2),
.info__list .table td:nth-child(2) {
  width: 18%;
}

.info__list table {
  border: 1px solid #eaeaea;
  min-width: 1200px;
  overflow-x: auto;
}

.info__list .table thead th {
  border-color: #cccccc;
}

.percent {
  position: absolute;
  font-size: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2dcc70;
}

@media only screen and (max-width: 1200px) {
  .bar-chart {
    min-width: 600px;
  }
  .home__files {
    overflow-x: auto;
    padding: 20px 0px;
  }
  .file__item {
    min-width: 470px;
    overflow-x: auto;
    width: 100%;
    margin-bottom: 20px;
    padding: calc(20px);
  }
  .file__item:last-child {
    margin-bottom: 0px;
  }
  .file__img {
    /* max-width: 90px; */
    margin-right: 20px;
  }

  .home__files::-webkit-scrollbar {
    background-color: #ebebeb;
    height: 8px;
  }

  .home__files::-webkit-scrollbar-thumb {
    background-color: #c4c4c4;
    border-radius: 10px;
  }
}
