/* quản lý hồ sơ style */
.form-group {
  margin-bottom: 20px;
}

.rowqlds-ct {
  position: relative;
}

.rowqlds-ct .line {
  position: absolute;
  font-size: 16px;
  color: var(--text-color);
  left: 50%;
  top: 10px;
  transform: translateX(-50%);
}

.form__save {
  margin-top: 10px;
  padding-top: 30px;
  border-top: 1px solid #c0c1c4;
}

.qlds__list {
  margin-top: 35px;
  padding-top: 15px;
  overflow-x: auto;
}

.qlds__list table {
  overflow-x: auto;
  min-width: 1300px;
}

.qlds__list::-webkit-scrollbar {
  background-color: #ebebeb;
  height: 8px;
}

.qlds__list::-webkit-scrollbar-thumb {
  background-color: #c4c4c4;
  border-radius: 10px;
}

.qlds__list table td select {
  min-width: 120px;
  height: 34px;
}

.qlds__list table td a {
  color: #359ae1;
  text-decoration: underline;
}

.table thead th,
.table td {
  vertical-align: top;
}

.table td,
.table th {
  padding: 12px 6px;
}

.wrap-table .table thead th {
  white-space: nowrap;
}

.danhsach-ctrinh-table .table td,
.danhsach-ctrinh-table .table th {
  padding: 12px;
}

.danhsach-ctrinh-table {
  overflow-x: auto;
  margin-top: 35px;
}

.danhsach-ctrinh-table table {
  min-width: 650px;
}

.danhsach-ctrinh-table table td select {
  min-width: 120px;
}

.table td a {
  color: #359ae1;
  text-decoration: underline;
}

.table__inner tbody tr:nth-of-type(odd) {
  background-color: transparent;
}
